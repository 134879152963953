<script lang="ts" setup>
const route = useRoute();

const isAnyCategory = route.params.category !== undefined;
const isAnyManufacturer = route.params.manufacturerId !== undefined;
const manufacturer = useState<Manufacturer>('manufacturer');

const subtitleOptions = {
  default:
    'Oszczędź czas! Wybierz swoje urządzenie, wskaż usterkę i uzyskaj najlepsze oferty napraw w Twojej okolicy. Szybko i efektywnie!',
  smartphone:
    'Oszczędź czas! Wybierz swój smartfon, wskaż usterkę i uzyskaj najlepsze oferty napraw w Twojej okolicy. Szybko i efektywnie!',
  tablet:
    'Oszczędź czas! Wybierz swój tablet, wskaż usterkę i uzyskaj najlepsze oferty napraw w Twojej okolicy. Szybko i efektywnie!',
  notebook:
    'Oszczędź czas! Wybierz swój laptop, wskaż usterkę i uzyskaj najlepsze oferty napraw w Twojej okolicy. Szybko i efektywnie!',
  console:
    'Oszczędź czas! Wybierz swoją konsolę, wskaż usterkę i uzyskaj najlepsze oferty napraw w Twojej okolicy. Szybko i efektywnie!',
};

const titleOptions = (manufacturerName: string | null) => ({
  smartphone: `Naprawa smartfona ${
    manufacturerName != null ? manufacturerName + ' ' : ''
  }w 3 krokach`,
  tablet: `Naprawa tabletu ${
    manufacturerName != null ? manufacturerName + ' ' : ''
  } w 3 krokach`,
  notebook: `Naprawa laptopa ${
    manufacturerName != null ? manufacturerName + ' ' : ''
  } w 3 krokach`,
  console: `Naprawa konsoli ${
    manufacturerName != null ? manufacturerName + ' ' : ''
  } w 3 krokach`,
});
const category = isAnyCategory
  ? categories.find((c) => c.name.toLowerCase() === route.params.category)
  : { name: 'default', uriId: 'default' };

const subtitle = subtitleOptions[category?.uriId] || subtitleOptions.default;
</script>

<template>
  <div>
    <h1 v-if="!isAnyCategory && !isAnyManufacturer" style="margin-bottom: 24px">
      Naprawa <span class="curved-bg">sprzętu</span> w 3 krokach
    </h1>
    <h1 v-else style="margin-bottom: 24px">
      {{ titleOptions(isAnyManufacturer ? manufacturer.name : null)[category!.uriId] }}
    </h1>
    <p>
      {{ subtitle }}
    </p>
  </div>
</template>

<style scoped>
.curved-bg {
  background: url('~/assets/curved-center-to-top.svg') center left;
  background-repeat: no-repeat;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 4px;
}

@media screen and (max-width: 480px) {
  .curved-bg {
    background: url('~/assets/curved-center-to-top.svg') center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
